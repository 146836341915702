<template>
  <div class="tw-plan-item" :class="{ 'tw-plan-item--selected': selected }">
    <div v-if="!isPackagesLoaded" class="tw-plan-item__loading">
      <WebSpinner
        size="24"
        color="transparent"
        background="currentColor"
        class="h-[27px]"
      />
    </div>
    <div v-show="isPackagesLoaded" class="tw-plan-item__handler">
      <div v-show="showRibbon" class="tw-plan-item__ribbon">
        <div class="tw-plan-item__ribbon-box">
          {{ getTextValue('ribbonText') }}
        </div>
      </div>
      <div class="tw-plan-item__container">
        <div class="tw-plan-item__info px-16 md:px-24">
          <div v-if="!!getTextValue('text1')" class="tw-plan-item__info-text1">
            {{ getTextValue('text1') }}
          </div>
          <div v-if="!!getTextValue('text2')" class="tw-plan-item__info-text2">
            {{ getTextValue('text2') }}
          </div>
          <div v-if="!!getTextValue('text3')" class="tw-plan-item__info-text3">
            {{ getTextValue('text3') }}
          </div>
        </div>
        <div v-if="showPriceCard" class="tw-plan-item__price-card w-[120px] px-8 md:w-[153px] md:px-24">
          <div v-if="getTextValue('priceText1')" class="tw-plan-item__price-card-text1">
            {{ getTextValue('priceText1') }}
          </div>
          <div v-if="getTextValue('priceText2')" class="tw-plan-item__price-card-text2">
            {{ getTextValue('priceText2') }}
          </div>
        </div>
      </div>
      <div v-if="showHighlight" class="tw-plan-item__highlight">
        <span class="tw-plan-item__highlight-text">{{ getTextValue('highlightText') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { PlanSelectionTextTypes, AlignmentPosition, ElementOptions } from '@shared/types/options';
import { $wait } from '@shared/utils/wait';
import WebSpinner from '@shared/components/spinner/index.vue';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['plan-selection']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'plan-selection'>>, default: () => ({}) },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  packagePriceInfo: { type: Object as PropType<Record<string, {price: string; trialPrice: string; savePercent: number }>>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
  order: { type: Number, default: 0 },
  selected: { type: Boolean, default: false },
});

const values = computed(() => JSON.parse(props.element.values || '{}'));
const planValues = computed(() => values.value.plans);
const planItemIndex = computed(() => props.order - 1 || 0);
const isPackagesLoaded = computed(() => {
  if (props.isEditorMode) return true;
  return props.pageData.packagesLoaded && !$wait.is(['applyingTemplateVariables']) || false
});
const showPriceCard = computed(() => props.config.design?.priceCard?.show);
const showRibbon = computed(() => props.config.ribbon?.[planItemIndex.value].show && !!getTextValue('ribbonText'));
const showHighlight = computed(
  () => props.config.highlight?.[planItemIndex.value].show && !!getTextValue('highlightText')
);

const flexAlignProperty: Record<AlignmentPosition, 'flex-start' | 'flex-end' | 'center'> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

function getTextValue(textType: PlanSelectionTextTypes | 'ribbonText' | 'highlightText') {
  return planValues.value?.[planItemIndex.value]?.[textType];
}

const planItemStyle = computed(() => {
  const pageOpt = props.pageOptions;
  const designOptions = props.config.design || {};
  const textOptions = props.config.text;
  const bgColor = {
    regular: designOptions.bgColors?.regular,
    hover: designOptions.bgColors?.hover,
    selected: designOptions.bgColors?.selected
  };

  const borderOpt = designOptions.border || {};
  const border = {
    radius: `${borderOpt.radius || 0}px`,
    width: `${borderOpt.width || 0}px`,
    colors: {
      regular: borderOpt.colors?.regular,
      hover: borderOpt.colors?.hover || pageOpt?.colors?.theme[0],
      selected: borderOpt.colors?.selected || pageOpt?.colors?.theme[0]
    }
  };

  const info = {
    text1: {
      fontSize: `${textOptions?.text1.fontSize}px`,
      fontFamily: textOptions?.text1.fontFamily || props.pageOptions.fontFamily,
      colors: {
        regular: textOptions?.text1.colors?.regular,
        hover: textOptions?.text1.colors?.hover,
        selected: textOptions?.text1.colors?.selected || pageOpt?.colors?.theme[0]
      },
      textAlign: textOptions?.text1.alignment,
      fontWeight: textOptions?.text1.textStyle?.bold ? 600 : 400,
      fontStyle: textOptions?.text1.textStyle?.italic ? 'italic' : 'normal',
      textDecoration: `${textOptions?.text1.textStyle?.underline ? 'underline' : ''} ${
        textOptions?.text1.textStyle?.strikeThrough ? 'line-through' : ''
      }`.trim()
    },
    text2: {
      fontSize: `${textOptions?.text2.fontSize}px`,
      fontFamily: textOptions?.text2.fontFamily || props.pageOptions.fontFamily,
      colors: {
        regular: textOptions?.text2.colors?.regular,
        hover: textOptions?.text2.colors?.hover,
        selected: textOptions?.text2.colors?.selected || pageOpt?.colors?.text[0]
      },
      textAlign: textOptions?.text2.alignment,
      fontWeight: textOptions?.text2.textStyle?.bold ? 500 : 400,
      fontStyle: textOptions?.text2.textStyle?.italic ? 'italic' : 'normal',
      textDecoration: `${textOptions?.text2.textStyle?.underline ? 'underline' : ''} ${
        textOptions?.text2.textStyle?.strikeThrough ? 'line-through' : ''
      }`.trim()
    },
    text3: {
      fontSize: `${textOptions?.text3.fontSize}px`,
      fontFamily: textOptions?.text3.fontFamily || props.pageOptions.fontFamily,
      colors: {
        regular: textOptions?.text3.colors?.regular,
        hover: textOptions?.text3.colors?.hover,
        selected: textOptions?.text3.colors?.selected
      },
      textAlign: textOptions?.text3.alignment,
      fontWeight: textOptions?.text3.textStyle?.bold ? 500 : 400,
      fontStyle: textOptions?.text3.textStyle?.italic ? 'italic' : 'normal',
      textDecoration: `${textOptions?.text3.textStyle?.underline ? 'underline' : ''} ${
        textOptions?.text3.textStyle?.strikeThrough ? 'line-through' : ''
      }`.trim()
    }
  };

  const verticalPadding = {
    verticalPadding: `${designOptions.verticalPadding?.current || 0}px`
  };

  return {
    border,
    bgColor,
    info,
    verticalPadding
  };
});

const priceCardStyle = computed(() => {
  const pageOpt = props.pageOptions;
  const designOptions = props.config.design || {};
  const textOptions = props.config.text;
  const priceCardOpt = designOptions.priceCard || {};

  return {
    colors: {
      regular: priceCardOpt.colors?.regular || pageOpt?.colors?.theme[2],
      hover: priceCardOpt.colors?.hover || pageOpt?.colors?.theme[2],
      selected: priceCardOpt.colors?.selected || pageOpt?.colors?.theme[0]
    },
    topLeftRadius: planItemStyle.value.border.radius,
    bottomLeftRadius:
      props.config.highlight?.[planItemIndex.value]?.show && !!getTextValue('highlightText')
        ? '0px'
        : planItemStyle.value.border.radius,
    priceText1: {
      fontSize: `${textOptions?.priceText1.fontSize}px`,
      fontFamily: textOptions?.priceText1.fontFamily || props.pageOptions.fontFamily,
      colors: {
        regular: textOptions?.priceText1.colors?.regular || pageOpt?.colors?.theme[0],
        hover: textOptions?.priceText1.colors?.hover || pageOpt?.colors?.theme[0],
        selected: textOptions?.priceText1.colors?.selected || pageOpt?.colors?.text[2]
      },
      alignSelf: flexAlignProperty[textOptions?.priceText1.alignment as AlignmentPosition],
      textAlign: textOptions?.priceText1.alignment,
      fontWeight: textOptions?.priceText1.textStyle?.bold ? 600 : 400,
      fontStyle: textOptions?.priceText1.textStyle?.italic ? 'italic' : 'normal',
      textDecoration: `${textOptions?.priceText1.textStyle?.underline ? 'underline' : ''} ${
        textOptions?.priceText1.textStyle?.strikeThrough ? 'line-through' : ''
      }`.trim()
    },
    priceText2: {
      fontSize: `${textOptions?.priceText2.fontSize}px`,
      fontFamily: textOptions?.priceText2.fontFamily || props.pageOptions.fontFamily,
      colors: {
        regular: textOptions?.priceText2.colors?.regular || pageOpt?.colors?.theme[0],
        hover: textOptions?.priceText2.colors?.hover || pageOpt?.colors?.theme[0],
        selected: textOptions?.priceText2.colors?.selected || pageOpt?.colors?.text[2]
      },
      alignSelf: flexAlignProperty[textOptions?.priceText2.alignment as AlignmentPosition],
      textAlign: textOptions?.priceText2.alignment,
      fontWeight: textOptions?.priceText2.textStyle?.bold ? 500 : 400,
      fontStyle: textOptions?.priceText2.textStyle?.italic ? 'italic' : 'normal',
      textDecoration: `${textOptions?.priceText2.textStyle?.underline ? 'underline' : ''} ${
        textOptions?.priceText2.textStyle?.strikeThrough ? 'line-through' : ''
      }`.trim()
    }
  };
});

const ribbonStyle = computed(() => {
  const ribbonOptions = props.config.ribbon?.[planItemIndex.value];
  const style = {
    colors: {
      background: ribbonOptions?.colors.background,
      text: ribbonOptions?.colors.text
    },
    radius: `${ribbonOptions?.radius || 0}px`,
    justifyContent: flexAlignProperty[ribbonOptions?.position as AlignmentPosition],
    fontFamily: ribbonOptions?.fontFamily || props.pageOptions.fontFamily,
    fontSize: `${ribbonOptions?.fontSize}px`,
    fontWeight: ribbonOptions?.textStyle?.bold ? 600 : 400,
    fontStyle: ribbonOptions?.textStyle?.italic ? 'italic' : 'normal',
    textDecoration: `${ribbonOptions?.textStyle?.underline ? 'underline' : ''} ${
      ribbonOptions?.textStyle?.strikeThrough ? 'line-through' : ''
    }`.trim()
  };
  return style;
});

const highlightStyle = computed(() => {
  const highlightOptions = props.config.highlight?.[planItemIndex.value];
  const style = {
    colors: {
      background: highlightOptions?.colors.background,
      text: highlightOptions?.colors.text
    },
    fontFamily: highlightOptions?.fontFamily || props.pageOptions.fontFamily,
    fontSize: `${highlightOptions?.fontSize}px`,
    fontWeight: highlightOptions?.textStyle?.bold ? 600 : 400,
    fontStyle: highlightOptions?.textStyle?.italic ? 'italic' : 'normal',
    textDecoration: `${highlightOptions?.textStyle?.underline ? 'underline' : ''} ${
      highlightOptions?.textStyle?.strikeThrough ? 'line-through' : ''
    }`.trim()
  };
  return style;
});
</script>

<style lang="postcss" scoped>
.tw-plan-item {
  --planItem-radius: v-bind(planItemStyle.border.radius);
  --planItem-bgColorRegular: v-bind(planItemStyle.bgColor.regular);
  --planItem-bgColorHover: v-bind(planItemStyle.bgColor.hover);
  --planItem-bgColorSelected: v-bind(planItemStyle.bgColor.selected);
  --planItem-borderColorRegular: v-bind(planItemStyle.border.colors.regular);
  --planItem-borderColorHover: v-bind(planItemStyle.border.colors.hover);
  --planItem-borderColorSelected: v-bind(planItemStyle.border.colors.selected);
  --planItem-borderWidth: v-bind(planItemStyle.border.width);
  --planItem-verticalPadding: v-bind(planItemStyle.verticalPadding.verticalPadding);

  --planItem-text1FontSize: v-bind(planItemStyle.info.text1.fontSize);
  --planItem-text1FontFamily: v-bind(planItemStyle.info.text1.fontFamily);
  --planItem-text1ColorRegular: v-bind(planItemStyle.info.text1.colors.regular);
  --planItem-text1ColorHover: v-bind(planItemStyle.info.text1.colors.hover);
  --planItem-text1ColorSelected: v-bind(planItemStyle.info.text1.colors.selected);
  --planItem-text1TextAlign: v-bind(planItemStyle.info.text1.textAlign);
  --planItem-text1FontWeight: v-bind(planItemStyle.info.text1.fontWeight);
  --planItem-text1FontStyle: v-bind(planItemStyle.info.text1.fontStyle);
  --planItem-text1TextDecoration: v-bind(planItemStyle.info.text1.textDecoration);

  --planItem-text2FontSize: v-bind(planItemStyle.info.text2.fontSize);
  --planItem-text2FontFamily: v-bind(planItemStyle.info.text2.fontFamily);
  --planItem-text2ColorRegular: v-bind(planItemStyle.info.text2.colors.regular);
  --planItem-text2ColorHover: v-bind(planItemStyle.info.text2.colors.hover);
  --planItem-text2ColorSelected: v-bind(planItemStyle.info.text2.colors.selected);
  --planItem-text2TextAlign: v-bind(planItemStyle.info.text2.textAlign);
  --planItem-text2FontWeight: v-bind(planItemStyle.info.text2.fontWeight);
  --planItem-text2FontStyle: v-bind(planItemStyle.info.text2.fontStyle);
  --planItem-text2TextDecoration: v-bind(planItemStyle.info.text2.textDecoration);

  --planItem-text3FontSize: v-bind(planItemStyle.info.text3.fontSize);
  --planItem-text3FontFamily: v-bind(planItemStyle.info.text3.fontFamily);
  --planItem-text3ColorRegular: v-bind(planItemStyle.info.text3.colors.regular);
  --planItem-text3ColorHover: v-bind(planItemStyle.info.text3.colors.hover);
  --planItem-text3ColorSelected: v-bind(planItemStyle.info.text3.colors.selected);
  --planItem-text3TextAlign: v-bind(planItemStyle.info.text3.textAlign);
  --planItem-text3FontWeight: v-bind(planItemStyle.info.text3.fontWeight);
  --planItem-text3FontStyle: v-bind(planItemStyle.info.text3.fontStyle);
  --planItem-text3TextDecoration: v-bind(planItemStyle.info.text3.textDecoration);

  --priceCard-topLeftRadius: v-bind(priceCardStyle.topLeftRadius);
  --priceCard-bottomLeftRadius: v-bind(priceCardStyle.bottomLeftRadius);
  --priceCard-bgColorRegular: v-bind(priceCardStyle.colors.regular);
  --priceCard-bgColorHover: v-bind(priceCardStyle.colors.hover);
  --priceCard-bgColorSelected: v-bind(priceCardStyle.colors.selected);

  --priceCard-text1FontSize: v-bind(priceCardStyle.priceText1.fontSize);
  --priceCard-text1FontFamily: v-bind(priceCardStyle.priceText1.fontFamily);
  --priceCard-text1ColorRegular: v-bind(priceCardStyle.priceText1.colors.regular);
  --priceCard-text1ColorHover: v-bind(priceCardStyle.priceText1.colors.hover);
  --priceCard-text1ColorSelected: v-bind(priceCardStyle.priceText1.colors.selected);
  --priceCard-text1TextAlign: v-bind(priceCardStyle.priceText1.textAlign);
  --priceCard-text1AlignSelf: v-bind(priceCardStyle.priceText1.alignSelf);
  --priceCard-text1FontWeight: v-bind(priceCardStyle.priceText1.fontWeight);
  --priceCard-text1FontStyle: v-bind(priceCardStyle.priceText1.fontStyle);
  --priceCard-text1TextDecoration: v-bind(priceCardStyle.priceText1.textDecoration);

  --priceCard-text2FontSize: v-bind(priceCardStyle.priceText2.fontSize);
  --priceCard-text2FontFamily: v-bind(priceCardStyle.priceText2.fontFamily);
  --priceCard-text2ColorRegular: v-bind(priceCardStyle.priceText2.colors.regular);
  --priceCard-text2ColorHover: v-bind(priceCardStyle.priceText2.colors.hover);
  --priceCard-text2ColorSelected: v-bind(priceCardStyle.priceText2.colors.selected);
  --priceCard-text2TextAlign: v-bind(priceCardStyle.priceText2.textAlign);
  --priceCard-text2AlignSelf: v-bind(priceCardStyle.priceText2.alignSelf);
  --priceCard-text2FontWeight: v-bind(priceCardStyle.priceText2.fontWeight);
  --priceCard-text2FontStyle: v-bind(priceCardStyle.priceText2.fontStyle);
  --priceCard-text2TextDecoration: v-bind(priceCardStyle.priceText2.textDecoration);

  --ribbon-bgColor: v-bind(ribbonStyle.colors.background);
  --ribbon-textColor: v-bind(ribbonStyle.colors.text);
  --ribbon-fontSize: v-bind(ribbonStyle.fontSize);
  --ribbon-radius: v-bind(ribbonStyle.radius);
  --ribbon-fontWeight: v-bind(ribbonStyle.fontWeight);
  --ribbon-fontStyle: v-bind(ribbonStyle.fontStyle);
  --ribbon-textDecoration: v-bind(ribbonStyle.textDecoration);
  --ribbon-justifyContent: v-bind(ribbonStyle.justifyContent);
  --ribbon-fontFamily: v-bind(ribbonStyle.fontFamily);

  --highlight-bgColor: v-bind(highlightStyle.colors.background);
  --highlight-textColor: v-bind(highlightStyle.colors.text);
  --highlight-fontSize: v-bind(highlightStyle.fontSize);
  --highlight-fontWeight: v-bind(highlightStyle.fontWeight);
  --highlight-fontStyle: v-bind(highlightStyle.fontStyle);
  --highlight-textDecoration: v-bind(highlightStyle.textDecoration);
  --highlight-fontFamily: v-bind(highlightStyle.fontFamily);

  @apply flex cursor-pointer flex-col overflow-hidden transition-all;

  background-color: var(--planItem-bgColorRegular);
  border-width: var(--planItem-borderWidth, 2px);
  border-color: var(--planItem-borderColorRegular, transparent);
  border-radius: var(--planItem-radius, 24px);

  &__ribbon {
    @apply absolute flex w-full px-24;

    justify-content: var(--ribbon-justifyContent);
    font-family: var(--ribbon-fontFamily);
    font-size: var(--ribbon-fontSize);
    transform: translateY(calc(-50% - calc(var(--planItem-borderWidth) / 2)));
    &-box {
      @apply truncate py-4 px-24;
      background-color: var(--ribbon-bgColor);
      color: var(--ribbon-textColor);
      border-radius: var(--ribbon-radius);
      font-weight: var(--ribbon-fontWeight);
      font-style: var(--ribbon-fontStyle);
      text-decoration: var(--ribbon-textDecoration);
      line-height: 1.4;
    }
  }

  &__container {
    @apply flex items-stretch justify-between;
  }

  &__info {
    @apply flex flex-1 flex-col justify-center rtl:items-start;
    padding-top: var(--planItem-verticalPadding);
    padding-bottom: var(--planItem-verticalPadding);
    word-break: break-word;

    &-text1 {
      color: var(--planItem-text1ColorRegular);
      font-size: var(--planItem-text1FontSize);
      font-family: var(--planItem-text1FontFamily);
      line-height: calc(var(--planItem-text1FontSize) * 1.25);
      text-align: var(--planItem-text1TextAlign);
      font-weight: var(--planItem-text1FontWeight);
      font-style: var(--planItem-text1FontStyle);
      text-decoration: var(--planItem-text1TextDecoration);
    }
    &-text2 {
      color: var(--planItem-text2ColorRegular);
      font-size: var(--planItem-text2FontSize);
      font-family: var(--planItem-text2FontFamily);
      line-height: calc(var(--planItem-text2FontSize) * 1.71);
      text-align: var(--planItem-text2TextAlign);
      font-weight: var(--planItem-text2FontWeight);
      font-style: var(--planItem-text2FontStyle);
      text-decoration: var(--planItem-text2TextDecoration);
    }
    &-text3 {
      color: var(--planItem-text3ColorRegular);
      font-size: var(--planItem-text3FontSize);
      font-family: var(--planItem-text3FontFamily);
      line-height: calc(var(--planItem-text3FontSize) * 1.33);
      text-align: var(--planItem-text3TextAlign);
      font-weight: var(--planItem-text3FontWeight);
      font-style: var(--planItem-text3FontStyle);
      text-decoration: var(--planItem-text3TextDecoration);
    }
  }

  &__price-card {
    @apply flex flex-col items-center justify-center gap-8 text-center transition-colors;
    padding-top: var(--planItem-verticalPadding);
    padding-bottom: var(--planItem-verticalPadding);
    background-color: var(--priceCard-bgColorRegular);
    border-top-left-radius: var(--priceCard-topLeftRadius);
    border-bottom-left-radius: var(--priceCard-bottomLeftRadius);
    word-break: break-word;
    &-text1 {
      color: var(--priceCard-text1ColorRegular);
      font-size: var(--priceCard-text1FontSize);
      font-family: var(--priceCard-text1FontFamily);
      line-height: calc(var(--priceCard-text1FontSize) * 1.25);
      text-align: var(--priceCard-text1TextAlign);
      align-self: var(--priceCard-text1AlignSelf);
      font-weight: var(--priceCard-text1FontWeight);
      font-style: var(--priceCard-text1FontStyle);
      text-decoration: var(--priceCard-text1TextDecoration);
    }
    &-text2 {
      color: var(--priceCard-text2ColorRegular);
      font-size: var(--priceCard-text2FontSize);
      font-family: var(--priceCard-text2FontFamily);
      line-height: calc(var(--priceCard-text2FontSize) * 1.33);
      text-align: var(--priceCard-text2TextAlign);
      align-self: var(--priceCard-text2AlignSelf);
      font-weight: var(--priceCard-text2FontWeight);
      font-style: var(--priceCard-text2FontStyle);
      text-decoration: var(--priceCard-text2TextDecoration);
    }
  }

  &__highlight {
    @apply truncate px-16 py-4 text-center;
    background-color: var(--highlight-bgColor);
    color: var(--highlight-textColor);
    text-decoration: var(--highlight-textDecoration);
    font-family: var(--highlight-fontFamily);
    font-size: var(--highlight-fontSize);
    font-style: var(--highlight-fontStyle);
    font-weight: var(--highlight-fontWeight);
    line-height: 1.4;
  }

  &__loading {
    @apply flex min-h-[113px] items-center justify-center;
    background-color: var(--planItem-bgColorRegular, #FFFFFF);
  }

  /* Hover */
  &:hover:not(.tw-plan-item--selected) {
    background-color: var(--planItem-bgColorHover);
    border-color: var(--planItem-borderColorHover);
    .tw-plan-item {
      &__info {
        &-text1 {
          color: var(--planItem-text1ColorHover);
        }
        &-text2 {
          color: var(--planItem-text2ColorHover);
        }
        &-text3 {
          color: var(--planItem-text3ColorHover);
        }
      }
      &__price-card {
        background-color: var(--priceCard-bgColorHover);
        &-text1 {
          color: var(--priceCard-text1ColorHover);
        }
        &-text2 {
          color: var(--priceCard-text2ColorHover);
        }
      }
    }
  }

  /* Selected */
  &--selected {
    background-color: var(--planItem-bgColorSelected);
    border-color: var(--planItem-borderColorSelected, transparent);
    .tw-plan-item {
      &__info {
        &-text1 {
          color: var(--planItem-text1ColorSelected);
        }
        &-text2 {
          color: var(--planItem-text2ColorSelected);
        }
        &-text3 {
          color: var(--planItem-text3ColorSelected);
        }
      }
      &__price-card {
        background-color: var(--priceCard-bgColorSelected);
        &-text1 {
          color: var(--priceCard-text1ColorSelected);
        }
        &-text2 {
          color: var(--priceCard-text2ColorSelected);
        }
      }
    }
  }
}
</style>
